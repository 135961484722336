import validateForm from './formValidator'

export function changeHelper(e, component) {
    let { name, value } = e.target
    if(name.indexOf('password') > -1 || name.indexOf('email') > -1) {
        value = value.trim();
    }
    component.setState({ [name]: value })
    setTimeout(() => {
        validateForm(component);
    })
}

export function maskToggler(name, component) {
    const value = !component.state[name]
    component.setState({ [name]: value })
}

export function submitValidator(e, component, cb, passOpt) {
    e.preventDefault()
    if(component.state.errors.length || !validateForm(component, true, passOpt)) {
        return;
    }
    component.setState({ buttonState: true, buttonText: 'Submitting...'});
    component[cb]();
}

export function fileHelper(e, component) {
    const { files } = e.target;
    if(files) {
        const fileArr = Array.from(files)
        if(!fileArr.every(file => file.size < 512000000)) {
            return component.setState({ errors: [ 'File size must not exceed 512mb.' ]})
        } else {
            var reader = new FileReader();
            reader.onload = function(e) {
                component.setState({ showModal: true, profileImageSrc: e.target.result })  
                setTimeout(() => {
                    validateForm(component);
                })
            }
            if(typeof fileArr[0] != 'undefined') {
                reader.readAsDataURL(fileArr[0])
            }
        }
    }
}