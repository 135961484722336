export default class Password { 
    constructor(password, otherPassword) {
        this.stringVal = password;
        this.errorMessage = otherPassword !== undefined ? 'Passwords do not match.' : 'Password must contain at least one number, one special character, and be at least 8 characters long.';
        this.errors = [];
        this.eightChars = /(?=.{8,})/;
        this.oneNum = /(?=.*[0-9])/;
        this.oneSpecChar = /(?=.*[!"#$%&'()*+,-.\/:;<=>?@\[\\\]^_`{|}~])/; // https://owasp.org/www-community/password-special-characters
        this.passwordMatch = password === otherPassword;
        if(!this.eightChars.test(password))
            this.errors.push('len');
        if(!this.oneNum.test(password))
            this.errors.push('num');
        if(!this.oneSpecChar.test(password))
            this.errors.push('spec');
        this.isValid = otherPassword !== undefined ? this.passwordMatch : !Boolean(this.errors.length);
    }
}