import React from "react"
// const rrrKey = 'https://script.google.com/macros/s/AKfycbyR6IlSla9hh_jY_vdFLTwdSC14iv455ONcxMUoBs2LDLq4xsg/exec'
const rrrKey =
  "https://script.google.com/a/macros/boomerangtransport.net/s/AKfycbyR6IlSla9hh_jY_vdFLTwdSC14iv455ONcxMUoBs2LDLq4xsg/exec"

export default function IframeNewRide(props) {
  return (
    <React.Fragment>
      <div
        style={{
          textAlign: "center",
          color: "#b1b1b1",
          fontFamily: "monospace",
        }}
      >
        <strong>
          **Are you seeing: Sorry, unable to open the file at this time???{" "}
        </strong>
        If so just clear your browser cache or log out of other Google accounts
        and only use one or view this page using Incognito.
      </div>
      <iframe
        src={rrrKey}
        title="Rapid Ride Request"
        frameBorder="0"
        style={{
          border: "0",
          width: "100vw",
          height: "calc(100vh - 69.15px)",
          overflowY: "hidden",
          position: "absolute",
          overflowX: "auto",
        }}
        allowfullscreen="true"
      ></iframe>
    </React.Fragment>
  )
}
