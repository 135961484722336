import React from 'react'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import { IconContext } from 'react-icons'
import FormControl from 'react-bootstrap/FormControl'

export default function(props) {
    return (
        <div className="rel_cont">
        <FormControl type={props.showPassword ? "text" : "password"} name={props.name} autoComplete="none" value={props.value} onChange={props.handleChange} placeholder={props.placeholder || "Password"} className="mr-sm-2" />
        <IconContext.Provider className="abs_right" value={{ className: "abs_right", color: '7b7b7b', size: '1rem', title: props.viewKey, style: { margin: '0 .125rem' } }}>
            {props.showPassword ?
                <BsFillEyeSlashFill onClick={() => props.handleView(props.viewKey)} />
                :
                <BsFillEyeFill onClick={() => props.handleView(props.viewKey)} />
            }
        </IconContext.Provider>
        </div>
    )
}

