import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import FormErrors from '../Helpers/FormErrors'
import Card from 'react-bootstrap/Card'
import axios from 'axios'
import stdError from '../../../utils/error'
import { changeHelper, submitValidator } from '../../../utils/formHelper'

export class ForgotPassword extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            serverEmailSuccess: false,
            buttonState: false,
            buttonText: "Submit",
            errors: []
        }
        this.emailSentMsg = <p>Please check your email to reset your password</p>;
    }
    async submit() {
        let result = await axios.get(`/api/pass_reset?email=${this.state.email}`)
        .catch(err => stdError(err, this));
        if (result && result.data && result.data.success) {
            this.setState({ email: '', serverEmailSuccess: true })
        }
    }
    render() {
        const forgotPassForm = (
            <Form>
                <FormControl type="text" name="email" value={this.state.email} onChange={e => changeHelper(e, this)} placeholder="Email" className="mr-sm-2" />
                <Button variant="outline-success" onClick={e => submitValidator(e, this, 'submit')} disabled={this.state.buttonState} className="tb_sm_rem">{this.state.buttonText}</Button>
                <FormErrors errors={this.state.errors}/>
            </Form>
        );
        return (
            <React.Fragment>
                {this.state.serverEmailSuccess ? this.emailSentMsg : forgotPassForm}
            </React.Fragment>
        )
    }
}
export default ForgotPassword



