import axios from 'axios'

export default async function verifyLogin(component, history) {
  console.log('call to get user obj')
  const result = await axios.get('/api/user').catch((e) => {
    console.log(
      `Error in attempting to verify if the user is currently logged in: `,
      e
    )
  })
  if (result?.data) {
    const mountedUser = result.data.user
    if (history && !mountedUser) {
      return history.push('/')
    }
    if (mountedUser) {
      console.log(
        `Success in verifying that this user is logged in: ${mountedUser}`
      )
    }
    if (!history && component) {
      component.setState({
        user: mountedUser,
        superUser: result.data.superUser,
        loggedIn: mountedUser ? true : false,
      })
    }
  }
}
