import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
export class ImageResizeModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            crop: {
                unit: "%",
                width: 30,
                aspect: 1 / 1
            },
            croppedImage: null,
            croppedImageUrl: ''
        }
    }
    onImageLoaded = image => {
        this.imageRef = image
    }
    onCropComplete = crop => {
        if (this.imageRef && crop.width && crop.height) {
            this.getCroppedImg(this.imageRef, crop)
        }
    }
    getCroppedImg(image, crop) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                this.dataURLtoFile(reader.result)
            }
        })
    }
    dataURLtoFile(croppedImageUrl) {
        let arr = croppedImageUrl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        // const suffix = mime.split('/')[1]
        const filename = Date.now();
        const croppedImage = new File([u8arr], filename, { type: mime });
        console.log('cropped image ', croppedImage)
        this.setState({ croppedImage, croppedImageUrl })
    }
    // setCrop(crop) {
    //     console.log(crop)
    //     this.setState({ crop })
    // }
    selectCrop() {
        console.log(this.state.croppedImage)
        this.props.setCrop(this.state.croppedImage, this.state.croppedImageUrl, false)
    }
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                
                {/* <Modal.Header closeButton={false}>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header> */}
                {/* <Modal.Body> */}
                <ReactCrop 
                        {...{ 
                            src: this.props.src, 
                            crop: this.state.crop, 
                            onChange: crop => this.setState({crop}), 
                            onImageLoaded: this.onImageLoaded, 
                            onComplete: this.onCropComplete 
                        }} 
                    />
                {/* </Modal.Body> */}
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.cancel(null, true)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => this.selectCrop()}>
                        Crop
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ImageResizeModal
