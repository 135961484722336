import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import FormErrors from '../Helpers/FormErrors'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Password from '../Helpers/Password'
import stdError from '../../../utils/error'
import axios from 'axios'
import { changeHelper, maskToggler, submitValidator } from '../../../utils/formHelper'

export class Login extends Component {
    constructor() {
        super()
        this.state = {
            buttonState: false,
            buttonText: 'Login',
            email: '',
            password: '',
            errors: [],
        }
    }
    async login() {
        const { email, password } = this.state
        const result = await axios.post('/api/login', { email, password })
          .catch( e => stdError(e, this, 'Login'))
        console.log(result)
        if(result && result.data && result.data.unverified) {
            this.props.history.push(`/verify/resend/${result.data.id}?login=true`)
        }else if (result && result.data) {
          this.props.setUser({ user: result.data.user, superUser: result.data.superUser, loggedIn: result.data.user && result.data.user.id ? true : false })
          this.props.history.push("/home/rides/today")
        }
    }
    render() {
        return (
            <React.Fragment>
                <Form>
                    <FormControl type="text" name="email" value={this.state.email} onChange={e => changeHelper(e,this)} placeholder="Email" className="mr-sm-2" />
                    <Password name="password" showPassword={this.state.showPassword} value={this.state.password} handleChange={e => changeHelper(e,this)} handleView={() => maskToggler('showPassword', this)} viewKey="showPassword" />
                    <Button variant="outline-success" disabled={this.state.buttonState} onClick={e => submitValidator(e, this, 'login')} className="tb_sm_rem r1rem">{this.state.buttonText}</Button>
                    <a style={{margin: '0em .3em'}} href="/forgot"><small>Forgot Password?</small></a>                
                    <FormErrors errors={this.state.errors}/>
                </Form>
            </React.Fragment>
        )
    }
}
export default withRouter(Login);



