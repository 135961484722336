import React from 'react'
import styles from './avatar.module.css'
export default function Avatar(props) {
    if (!props.user) {
        return null
    } else if (props.img) {
        return (
        <img src={props.img}
            className={`${props.imgclassProp || props.classProp || ''} ${styles.middle} ${styles.flex}`}
            alt="profile" title="Profile" onClick={props.handleOnClick}
        />)
    } else {
        return (<div className={` ${props.classProp || ''} ${styles.middle} ${styles.flex}`}
            title="Profile" onClick={props.handleOnClick}>
            {props.user && props.user.userFirstName && props.user.userFirstName.split("")[0]}
        </div>)
    }
}
