import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FormErrors from '../Helpers/FormErrors'
import Password from '../Helpers/Password'
import axios from 'axios'
import stdError from '../../../utils/error'
import { changeHelper, maskToggler, submitValidator } from '../../../utils/formHelper'

export class ResetPassword extends Component {
    constructor() {
        super()
        this.state = {
            buttonState: false,
            buttonText: 'Reset Password & Login',
            invalidToken: false,
            resetPasswordSuccess: false,
            password: '',
            password2: '',
            passMatch: 'none',
            errors: []
        }
        this.invalidTokenMessage = (
            <p>
                Your token has expired. Please click <a href="/forgot">here</a> to create a new one.
            </p>
        );
    }
    async resetPassword() {
        let { token } = this.props.match.params
        let payload = {
            password: this.state.password,
            token
        }
        let result = await axios.post(`/api/pass_reset`, payload)
        .catch( e => stdError(e, this, 'Reset Password & Login'))
        if (result && result.data && result.data.user) {
            console.log('Reset Email Sent Result => ', result)
            this.props.setUser({ user: result.data.user, loggedIn: result.data.user && result.data.user.id ? true : false })
            this.props.history.push("/home/rides/today");
        } else if(result && result.data && result.data.invalidToken) {
            this.setState({ invalidToken: true })
        }
    }
    render() { 
        const resetPassForm = (
            <Form>
                <Password name="password" showPassword={this.state.showPassword} value={this.state.password} handleChange={e => changeHelper(e, this)} handleView={() => maskToggler('showPassword', this)} viewKey="showPassword" />
                <Password name="password2" showPassword={this.state.showPassword2} value={this.state.password2} handleChange={e => changeHelper(e, this)} handleView={() => maskToggler('showPassword2', this)} viewKey="showPassword2" />              
                <Button variant="outline-success" onClick={e => submitValidator(e, this, 'resetPassword')} disabled={this.state.buttonState} className="tb_sm_rem">{this.state.buttonText}</Button>
                <FormErrors errors={this.state.errors} />
            </Form>
        );
        return (
            <React.Fragment>
                {this.state.invalidToken ? this.invalidTokenMessage : resetPassForm}
            </React.Fragment>
        )
    }
}
export default withRouter(ResetPassword)



