import Email from './Email'
import Password from './Password'

export default (component, submit, opt) =>  {
    const { email, password, password2 } = component.state;
    const custEmail = email !== undefined ? new Email(email) : undefined;
    const custPassword = password !== undefined && ((opt && password !== '') || !opt) ? new Password(password) : undefined;
    const custPassword2 = password2 !== undefined && ((opt && ( password2 !== '' || password !== '')) || !opt) ? new Password(password2, password) : undefined;    
    const errors = [custEmail, custPassword, custPassword2].filter(i => i && !i.isValid).map(i => i.errorMessage)
    if(submit) { 
        component.setState({ errors })
        return !Boolean(errors.length)
    } else {
        let errors_temp = component.state.errors.filter(e => errors.indexOf(e) > -1)
        component.setState({ errors: errors_temp })
    }
}