import React from 'react'

export default function MissiveChat(props) {
    React.useEffect(() => {
        /* Missive Live Chat */
        (function (d, w) {
            w.MissiveChatConfig = {
                "id": "c6258a78-07a6-467e-b593-c39316e92ad2"
            };
            let s = d.createElement('script');
            s.async = true;
            s.src = 'https://webchat.missiveapp.com/' + w.MissiveChatConfig.id + '/missive.js';
            
            if (d.head) {
                d.head.appendChild(s);
            }
            
        })(document, window)
        /* /Missive Live Chat */
        return unmount;
    }, []);
    const unmount = () => {
            document.getElementById('missive-webchat').remove();  
    }
    return (<div></div>)
}
