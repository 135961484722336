import React from 'react'
import ForgotPassword from '../../../components/authForms/ForgotPassword'
import ResetPassword from '../../../components/authForms/ResetPassword'
import Card from 'react-bootstrap/Card'
import logo from "../../../assets/BoomLogo-header.jpg"

export default function ForgotPasswordScreen(props) {
    return (
        <div className="flexContainer">
            <a href="/" >
                <img src={logo} alt="Boomerang Logo" className="boomLogo tb2rem" />
            </a>
            <Card className="card_custom">
                <Card.Body>
                    <Card.Title>Reset Password</Card.Title>
                    {props.reset ? <ResetPassword setUser={props.setUser} /> : <ForgotPassword  />}
                </Card.Body>
            </Card>
        </div>
    )
}
