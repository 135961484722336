import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import verifyLogin from '../../utils/verifyLogin'

const SecuredRoute = props => {
    
    let { render: Component, path, location, loggedIn, exact, history } = props;
    React.useEffect(() => {
        if (props.useEffect) {
            (async () => {
                verifyLogin(null, history)
            })();
        }
    })
    if ((exact && location.pathname.toLowerCase() === path.toLowerCase()) || !exact) {
        console.log(loggedIn)
        if (loggedIn)
            return <Route path={path} render={() => <Component />} />
        else if (loggedIn === null)
            return null
        else if (!loggedIn)
            return <Redirect to={{ pathname: '/' }} />
    } else {
        return null
    }
}
export default withRouter(SecuredRoute);