import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import FormErrors from '../../authForms/Helpers/FormErrors'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import { changeHelper, submitValidator } from '../../../utils/formHelper'
import stdError from '../../../utils/error'
import styles from './contactus.module.css'
import { IconContext } from "react-icons";
import { FaPhoneSquareAlt } from "react-icons/fa";

class ContactUs extends Component {
    constructor() {
        super()
        this.state = {
            contactName: '',
            email: '',
            contactMessage: '',
            buttonState: false,
            buttonText: "Submit",
            messageSent: false,
            errors: []
        }
    }

    componentDidMount() {

    }

    async contactSubmit() {
        console.log('contact submit buton clicked')
        const { contactName, email, contactMessage } = this.state;

        let result = await axios.get(`/api/contact_submit?name=${this.state.contactName}\
        &email=${this.state.email}\
        &message=${this.state.contactMessage}`)
            .catch(err => stdError(err, this));
        if (result && result.data && result.data.success) {
            this.setState({
                contactName: '',
                email: '',
                contactMessage: '',
                buttonState: false,
                buttonText: "Submit",
                messageSent: true,
                errors: []
            })
            window.setTimeout(() => {
                this.setState({ messageSent: false })
            }, 4000)
        }

    }

    render() {
        return (
            <div className="form-wrapper">
                <Form className="form-body">
                    <div className="form-heading">We'd love to hear from you.</div>
                    <br />
                    <div className={styles.callUs}>
                        <IconContext.Provider value={{ color: "gray", size: "1.75em", title: "phone-icon", className: "global-class-name" }}>
                            <div>
                                <FaPhoneSquareAlt /> <a target="_blank" href="tel:704-266-6061">704-266-6061</a>
                            </div>
                        </IconContext.Provider>
                    </div>
                    <br />
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Name</Form.Label>
                        <FormControl required type="text" placeholder="Enter name"
                            name="contactName" value={this.state.contactName} onChange={e => changeHelper(e, this)} />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <FormControl required type="email" placeholder="Enter email"
                            name="email" value={this.state.email} onChange={e => changeHelper(e, this)} />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>How Can We Help?</Form.Label>
                        <FormControl required as="textarea" placeholder="Message" rows="5"
                            name="contactMessage" value={this.state.contactMessage} onChange={e => changeHelper(e, this)} />
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={this.state.buttonState}
                        onClick={e => submitValidator(e, this, 'contactSubmit', true)}>{this.state.buttonText}
                    </Button> {this.state.messageSent ? <span className={styles.sentSuccess}>Message Sent Successfully!</span> : null}
                    <FormErrors errors={this.state.errors} />
                </Form>
            </div>
        )
    }
}

export default withRouter(ContactUs);
