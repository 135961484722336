import React from 'react'
import Signup from '../../../components/authForms/Signup'
import Card from 'react-bootstrap/Card'
import logo from "../../../assets/BoomLogo-header.jpg"



function SignupScreen(props) {
    return (
        <div className="flexContainer" >
            {/* <Card style={{ width: '18rem' }}> */}
            <a href="/" >
                <img src={logo} alt="Boomerang Logo" className="boomLogo tb2rem" />
            </a> 
            <Card className="card_custom">
                <Card.Body>
                    <Card.Title>Sign Up</Card.Title>
                    <Signup setUser={props.setUser} />
                </Card.Body>
            </Card>
        </div>
    )
}
export default SignupScreen
