import React from 'react'
import { Card, Button } from 'react-bootstrap'
import logo from "../../../assets/BoomLogo-header.jpg"
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import stdError from '../../../utils/error'

function ResendVerificationScreen(props) {
    const [ButtonText, setButtonText] = React.useState('Re-send Verification')
    const [LinkText, setLinkText] = React.useState('here')
    const [Success, setSuccess] = React.useState(false)
    const [Expired, setExpired] = React.useState(false)
    const [FromLogin, setFromLogin] = React.useState(false)
    const [ReSendLinkId, setReSendLinkId] = React.useState(false);
    const [AccountVerified, setAccountVerified] = React.useState(false) 
    const [ButtonState, setButtonState] = React.useState(false)
    // const [Suspense, setSuspense] = React.useState(true)
    const {location, signup, login} = props;
    React.useEffect( () => {
        if(location && location.search.slice(1).split("&").indexOf('login=true') > -1) {
            let fromLogin = Boolean(location.search.slice(1).split("&")[location.search.slice(1).split("&").indexOf('login=true')].split('=')[1])
            console.log(fromLogin)
            setFromLogin(fromLogin)
            // setSuspense(false)
        }
        if(signup) {
            setSuccess(true);
        }
        if(login) {
            (async function(){
                const { token } = props.match.params
                const result = await axios.get(`/api/verification/${token}`).catch(stdError)
                console.log('RESULT ', result)
                if(result && result.data && result.data.expired) {
                    setExpired(true)
                    if(result.data.resendLinkId){
                        setReSendLinkId(result.data.resendLinkId)
                    } else if (result.data.verified) {
                        setAccountVerified(true)
                    }
                }
                if (result && result.data && result.data.user) {
                    props.setUser({ 
                        user: result.data.user, 
                        superUser: result.data.superUser, 
                        loggedIn: result.data.user && result.data.user.id ? true : false 
                    })
                    props.history.push("/home/rides/today");
                }
            })()
        }
    },[location,signup,login, props])
    // console.log('Props => ',fromLogin)

    const resendVerification = async () => {
        setButtonText('Sending...')
        setLinkText('Sending....')
        setButtonState(true)
        const { id } = props.match.params
        console.log( id )
        const result = await axios.get(`/api/verification/resend/${id}`).catch(stdError)
        console.log( result )
        if(result && result.data && result.data.success) {
            console.log('success')
            setSuccess(true);
            setLinkText('here')
        } else if(result && result.data && result.data.expired) {
            console.log('expired')
            setExpired(true)
            if(result.data.resendLinkId){
                setLinkText('here')
                setReSendLinkId(result.data.resendLinkId)
            } else if (result.data.verified) {
                console.log('verified')
                setAccountVerified(true)
            }
            
        }
        setButtonState(false)
    }
    
    const accountExpiredMessage = <p>Your account expired. Please click <a href="/signup" >here</a> to sign up again.</p>
    const accountVerifiedMessage = <p>Your account has already been verified. Click <a href="/" >here</a> to log in.</p>
    const resendLinkMessage = <><p>This verification link has expired. Use the most recent link sent to your email.</p><p>If you need to have the email re-sent, click <a href={`/verify/resend/${ReSendLinkId}`} >{LinkText}</a>.</p></>
    const explaination  = ( 
        <>
            <p>Your Account is unverified. Check your email to complete verification.</p>
            <p><em>Unverified accounts will expire after 30 minutes.</em></p><p>If you need to have the email re-sent, click <a href={`/verify/resend/${props.match.params.id}`} >{LinkText}</a>.</p></>
    )
    const button = <Button onClick={resendVerification} disabled={ButtonState}>{ButtonText}</Button>
    const confirmation = <><p>A link to verify your account and log you in has been sent to your email address.</p><p><em>Unverified accounts will expire after 30 minutes.</em></p><p>If you need to have the email re-sent, click <a href={`/verify/resend/${props.match.params.id}`} >{LinkText}</a>.</p></>
    const expired = (Expired && AccountVerified && accountVerifiedMessage) || (Expired && ReSendLinkId && resendLinkMessage) || (Expired && accountExpiredMessage) || <></>
    const verify = () => (Success && confirmation) || (Expired && expired) || (FromLogin && explaination) || button
    if(props.location ) {
        return (
            <>
            {((props.login && Expired) || !props.login) && (
                <div className="flexContainer" >
                    <a href="/" >
                        <img src={logo} alt="Boomerang Logo" className="boomLogo tb2rem" />
                    </a>
                    <Card className="card_custom center">
                        <Card.Body>
                        <Card.Title>Verify Account</Card.Title>
                            {(props.login && expired) || verify()}
                        </Card.Body>
                    </Card>
                </div>
            )}
            </>
        )
    } else {
        return <></>
    }
}
export default withRouter(ResendVerificationScreen)
