import React, { Component } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import LoginScreen from './screens/authScreens/LoginScreen'
import SignupScreen from './screens/authScreens/SignupScreen'
import ForgotPasswordScreen from './screens/authScreens/ForgotPasswordScreen'
import ResendVerificationScreen from './screens/authScreens/ResendVerificationScreen'
import Home from './screens/Home'
import SecuredRoute from './components/navRouting/SecuredRoute'
import NotFound404 from './components/navRouting/NotFound404'
import verifyLogin from './utils/verifyLogin'
import './App.css'

export class App extends Component {
  constructor() {
    super()
    this.state = {
      user: null,
      loggedIn: null,
      superUser: null
    }
    this.setUser = this.setUser.bind(this)
  }
  setUser(payload) {
    this.setState(payload)
  }
  
  async componentDidMount() {
    
    await verifyLogin(this)
    console.log(this.state.user)
    if(this.state.user && window.location.pathname.toLowerCase().indexOf('home') < 0) {
      window.location.replace('/home/rides/today')
    }
    console.log(window.location.pathname)
    // console.log('call to get user obj')
    // const result = await axios.get('/api/user')
    //   .catch(e => {
    //     console.log(`Error in component did mount call: `, e)
    //   })
    // if(!result) {
    //   alert('Uncaught exception in call to server: ',result)
    // } else if (result.data) {
    //   const mountedUser = result.data.user
    //   console.log(`Success in component did mount call for ${result.data.user}`)
    //   this.setState({ user: mountedUser, superUser: result.data.superUser, loggedIn: mountedUser ? true : false })
    // } 
  }
  
  render() {
    return (
        <Router>
          <Switch>
             {/* Routes*/}
            <Route exact path='/' render={() =>
              <LoginScreen setUser={this.setUser} />
            } />
            <Route exact path='/signup' render={() =>
              <SignupScreen setUser={this.setUser} />
            } />
            <Route exact path='/verify/send/success/:id' render={() =>
              <ResendVerificationScreen setUser={this.setUser} signup/>
            } />
            <Route exact path='/verify/:token' render={() =>
              <ResendVerificationScreen setUser={this.setUser} login/>
            } />
            <Route exact path='/verify/resend/:id' render={() =>
              <ResendVerificationScreen />
            } />
            <Route exact path='/forgot' render={() =>
              <ForgotPasswordScreen />
            } />
            <Route exact path='/forgot/:token' render={() =>
              <ForgotPasswordScreen setUser={this.setUser} reset/>
            } />
            <SecuredRoute path='/home' loggedIn={this.state.loggedIn}  render={() =>
              <Home className="view" superUser={this.state.superUser} loggedIn={this.state.loggedIn} setUser={this.setUser} user={this.state.user} />
            } />
            <Route render={() => <NotFound404 />} />
          </Switch>
        </Router>
    )
  }
}
export default App;

