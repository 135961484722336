import React, { useRef } from 'react'
import { FaRegWindowClose } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import Avatar from '../Avatar'
import styles from './media.module.css'


const Media = props => {
    const inputEl = useRef(null)
    return (
        <React.Fragment>
            <div className={`${styles.container} `} onMouseLeave={() => props.setShowRemoveFile(null, null, false)} onMouseEnter={() => props.setShowRemoveFile(null, null, true)}>
                <Avatar user={props.user} img={props.img} styles={props.styles} />
                <input type="file" name="media" ref={inputEl} className={`${styles.middle} ${styles.hidden}`} onChange={props.onChange} accept="image/*" disabled={props.show && props.img} />
                <IconContext.Provider value={{ color: 'lightgrey', size: '1rem', title: "close button" }}>
                    <FaRegWindowClose className={`${styles.closeButton} ${props.show && props.img ? '' : styles.hide}`} onClick={() => props.setShowRemoveFile(inputEl.current)} />
                </IconContext.Provider>
            </div>
        </React.Fragment>
    )
}
export default Media

