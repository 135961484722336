import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import FormErrors from '../Helpers/FormErrors'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Password from '../Helpers/Password'
import { changeHelper, maskToggler, submitValidator } from '../../../utils/formHelper'
import stdError from '../../../utils/error'
import axios from 'axios'
import './signup.css'

export class Signup extends Component {
    constructor() {
        super()
        this.state = {
            userFirstName: '',
            userLastName: '',
            email: '',
            password: '',
            showPassword: false,
            password2: '',
            showPassword2: false,
            errors: [],
            buttonState: false,
            buttonText: "Create Account"
        }
    }
    async signup() {
        const { userFirstName, userLastName, email, password } = this.state;
        const result = await axios.post('/api/signup', { userFirstName, userLastName, email, password })
        .catch( e => stdError(e, this, "Create Account"))
        if(result && result.data && result.data.unverified) {
            this.props.history.push(`/verify/resend/${result.data.id}`)
        }
        if(result && result.data && result.data.success) {
            this.props.history.push(`/verify/send/success/${result.data.id}`)
        } else {
            console.log('Error in signup call')
        }
    }
    render() {
        return (
            <div>
                <Form>
                    <FormControl type="text" name="userFirstName" value={this.state.userFirstName} onChange={e => changeHelper(e, this)} placeholder="First Name" className="mr-sm-2" />
                    <FormControl type="text" name="userLastName" value={this.state.userLastName} onChange={e => changeHelper(e, this)} placeholder="Last Name" className="mr-sm-2" />
                    <FormControl type="text" name="email" value={this.state.email} onChange={e => changeHelper(e, this)} placeholder="Email" className="mr-sm-2" />
                    <Password name="password" showPassword={this.state.showPassword} value={this.state.password} handleChange={e => changeHelper(e, this)} handleView={() => maskToggler('showPassword', this)} viewKey="showPassword" />
                    <Password name="password2" showPassword={this.state.showPassword2} value={this.state.password2} handleChange={e => changeHelper(e, this)} handleView={() => maskToggler('showPassword2', this)} viewKey="showPassword2" />              
                    <Button variant="outline-success" disabled={this.state.buttonState} onClick={e => submitValidator(e, this, 'signup')} className="tb_sm_rem" >{this.state.buttonText}</Button>
                    <FormErrors errors={this.state.errors} />
                    {/* <FormControl type="submit" variant="outline-success" value="Create Account" onClick={this.loginClick} className="tb_sm_rem" />Create Account</FormControl> */}
                </Form>
            </div>
        )
    }
}
export default withRouter(Signup);



