export default function (error, component, buttonText) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if(error.response.data && error.response.data.serverMessage) {
        if(component && component.state.buttonState) {
          component.setState({ errors: [error.response.data.serverMessage], buttonState: false, buttonText: buttonText || 'Submit' })
        } else if (component){
          component.setState({ errors: [error.response.data.serverMessage ]});
        } else {
          console.log('Message: ', error.response.data.serverMessage);
          alert('Server Error: ' + error.response.data.serverMessage)

        }
      }
      console.log('Status: ', error.response.status);
      console.log('Headers: ', error.response.headers);  
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log('No Response Received. Here is Request: ', error.request);
        alert('No Response Received. Here is the Request: ' + JSON.stringify(error.request,null, 2));
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Request never sent. Here is Error: ', error.message);
      alert('Request Error: ' +  error.message)
    }
    console.log('Error Config: ',error.config);
  };