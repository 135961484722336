import React from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
// import Button from 'react-bootstrap/Button'
import logo from "../../../assets/BoomLogo-header.jpg"
import Avatar from '../../accountComponents/Helpers/Avatar'
import { BsBoxArrowInRight, BsArrowClockwise } from "react-icons/bs";
import { IconContext } from "react-icons";
import './topNavbar.css'

class TopNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.navToProfile = this.navToProfile.bind(this)
        this.logout = this.logout.bind(this)
        this.setDropdownOpen = this.setDropdownOpen.bind(this)
        this.state = {
            dropdown: false
        }
    }
    navToProfile(){
        window.location.replace('/home/profile')
    }
    async logout() {
        console.log('logout clicked')
        const result = await axios.post('/api/logout', { id: this.props.user.id })
            .catch(e => {
                console.log(`Error in post call to logout: `, e)
            })
        if (result && result.data) {
            console.log(`Success in post call to logout.`)
            this.props.setUser({ user: result.data.user, superUser: null, loggedIn: result.data && result.data.user ? true : false })
            this.props.history.push("/")
        }
    }
    setDropdownOpen() {
        const dropdown = this.state.dropdown;
        this.setState({ dropdown: !dropdown })
    }
    render() {
        return (
            <div>
                <Navbar className="navbar_c" expand={(this.props.superUser && 'lg') || true } >
                    <Navbar.Brand className="brand_c" href="/home/rides/today"><img src={logo} alt="Boomerang Logo" width="288" height="auto" style={{ borderRadius: '.325rem' }} /></Navbar.Brand>
                    <React.Fragment>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className={`full-width ${(this.props.superUser && 'super-user') || ''}`}>
                                {/* <Nav.Link href="Home">Home</Nav.Link> */}
                                {/* <Nav.Link href="#link">Link</Nav.Link> */}
                                {this.props.superUser &&
                                    <>
                                        <div className="read-only-card">
                                            <span style={{ textDecoration: 'underline' }} >{`Logged in as:`}</span> <br /><small style={{ display: 'contents' }}><strong>{`${this.props.user.email}`}</strong></small>
                                        </div>

                                        <NavDropdown onClick={this.setDropdownOpen} title="Users" className={`${this.state.dropdown ? 'clicked' : ''}`} id="basic-nav-dropdown">
                                            {this.props.superUser.map(user =>
                                                <NavDropdown.Item className="poppover-not-static" onClick={() => this.props.setUser({ user })} key={user.email} no-script-url>{user.email}</NavDropdown.Item>
                                            )}
                                        </NavDropdown>
                                    </>
                                }
                                {(this.props.location.pathname === "/home/rides/today" ||
                                    this.props.location.pathname === "/home/rides/future") &&
                                    <IconContext.Provider value={{ className: `${this.props.superUser ? 'super-user-tab-left' : 'tab-left'}`, style: { position: 'absolute', cursor: 'pointer', bottom: 'calc(0.2rem + 1px)', border: 'solid 1px black', borderBottom: 'unset', borderRadius: '.5rem .5rem 0 0 ', padding: '.1rem .1rem 0 .1rem', marginBottom: '-.3rem', background: 'white' }, color: '#929292', size: '2rem' }}>
                                        <BsArrowClockwise title="Refresh" onClick={() => window.location.reload()} />
                                    </IconContext.Provider>
                                }
                                <Avatar classProp={`topbar_custom topbar_right ${!this.props.superUser && 'avatar-right'}`} user={this.props.user} img={this.props.user.profileImageUrl} handleOnClick={this.navToProfile} />
                                {/* <div className="topbar_custom name_label">{this.props.user.userfirstName || this.props.user.email}</div> */}
                                <div className="logout_c">
                                    <IconContext.Provider value={{ color: 'rgb(146, 146, 146)', size: '3.5em' }}>
                                        <BsBoxArrowInRight onClick={this.logout} title="Logout" />
                                    </IconContext.Provider>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </React.Fragment>


                </Navbar>
            </div>
        )
    }
}
export default withRouter(TopNavbar)

