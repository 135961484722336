/*
@@@Props
  closeButton         (Bool)
  show                (Bool)
  onHide              (Func)
  header              (Bool)
  title               (String)
  body                (String || Component)
  bodyProps           (Map)
  onHide              (Func)
  handleSave          (Func)
  noFooter            (Bool)
  modalClass          (String)
  headerClass         (String)
  titleClass          (String)
  bodyClass           (String)    
  footerClass         (String)  
  closeButtonClass    (String)
  saveButtonClass     (String)  
  closeButtonVariant  (String)
  saveButtonVariant   (String)
  saveText            (String)
  closeText           (String)
*/
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ReusableModal(props) {

  return (
    <Modal className={props.modalClass} show={props.show} onHide={props.onHide}>
      {props.header && 
        <Modal.Header className={props.headerClass} closeButton={props.closeButton}>
          {props.title && 
            <Modal.Title className={props.titleClass}>
              {props.title}
            </Modal.Title>
          }
        </Modal.Header>
      }
      {typeof props.body === 'string' ?
        <Modal.Body className={props.bodyClass}>
          {props.body}
        </Modal.Body>
        :
        <props.body {...props.bodyProps}/>
      }
      {!props.noFooter &&
        <Modal.Footer className={props.footerClass}>
          <Button variant={props.closeButtonVariant || "secondary"} className={props.closeButtonClass} onClick={props.onHide}>
            {props.closeText || 'Close'}
          </Button>
          <Button variant={props.saveButtonVariant || "primary"} className={props.saveButtonClass} onClick={props.handleSave}>
            {props.saveText || 'Save'}
          </Button>
        </Modal.Footer>
      }
    </Modal>
  )
}
