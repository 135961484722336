import React from 'react'
import Alert from 'react-bootstrap/Alert'
import SidebarNav from '../../components/navRouting/SidebarNav'
import TopNavbar from '../../components/navRouting/TopNavbar'
import SecuredRoute from '../../components/navRouting/SecuredRoute'
import { Switch, Route, useRouteMatch, withRouter } from 'react-router-dom'
import EditProfile from '../../components/accountComponents/Profile'
import IframeToday from '../../components/navRouting/IframeToday'
import IframeFuture from '../../components/navRouting/IframeFuture'
import IframeNewRide from '../../components/navRouting/IframeNewRide'
import NotFound404 from '../../components/navRouting/NotFound404'
import ContactUs from '../../components/accountComponents/ContactUs'
import MissiveChat from './MissiveChat'
import './home.css'
import { } from 'dotenv/config'

// https://reactjsexample.com/react-side-nav-component/
import styles from './home.module.css'

function Home(props) {
    const [expanded, setExpanded] = React.useState(false)

    const { url } = useRouteMatch();    
    return (

        <React.Fragment>
            {process.env.REACT_APP_ALERT_AUTHENTICATED_MESSAGE &&
            <Alert variant='primary' style={{textAlign: 'center', marginBottom: '0'}}> 
                {process.env.REACT_APP_ALERT_AUTHENTICATED_MESSAGE}
            </Alert>
            }
            <TopNavbar setUser={props.setUser} superUser={props.superUser} user={props.user} />
            <div className={styles.view}>
                <SidebarNav url={url} expanded={expanded} setExpanded={setExpanded} />
                <main className={`${styles.main} ${expanded ? styles.expanded : ''}`} >
                    <Switch>
                        <SecuredRoute exact path={`${url}/rides/today`} loggedIn={props.loggedIn} render={() =>
                            <IframeToday providedKey={props.user.dsKeys.todaysDS} />
                        } useEffect/>
                        <SecuredRoute exact path={`${url}/rides/future`} loggedIn={props.loggedIn} render={() =>
                            <IframeFuture providedKey={props.user.dsKeys.futureDS} />
                        } useEffect/>
                        <SecuredRoute exact path={`${url}/rides/new`} loggedIn={props.loggedIn} render={() =>
                            <IframeNewRide />
                        } useEffect/>
                        <SecuredRoute exact path={`${url}/contact`} loggedIn={props.loggedIn} render={() =>
                            <ContactUs />
                        } useEffect/>
                        <SecuredRoute exact path={`${url}/profile`} loggedIn={props.loggedIn} render={() =>
                            <EditProfile setUser={props.setUser} user={props.user} />
                        } useEffect/>
                        <Route render={() => <NotFound404 />} />
                        {/* <Route exact path={`${url}/rides/today`} component={() => <IframeToday providedKey={props.user.dsKeys.todaysDS} />} /> */}
                        {/* <Route exact path={`${url}/rides/future`} component={() => <IframeFuture providedKey={props.user.dsKeys.futureDS} />} /> */}
                        {/* <Route exact path={`${url}/rides/new`} component={() => <IframeNewRide />} /> */}
                        {/* <Route exact path={`${url}/contact`} component={() => <ContactUs />} /> */}
                        {/* <Route exact path={`${url}/profile`} component={() => <EditProfileScreen setUser={props.setUser} user={props.user} />} /> */}
                    </Switch>
                </main>
            </div>
            <MissiveChat />
        </React.Fragment>
    )
}
export default withRouter(Home)