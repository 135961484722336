import React from 'react'
import Login from '../../../components/authForms/Login'
import Card from 'react-bootstrap/Card'
import Alert from 'react-bootstrap/Alert'
import logo from "../../../assets/BoomLogo-header.jpg"
import { } from 'dotenv/config'


function LoginScreen(props) {
    return (

        <div className="flexContainer" >
            {process.env.REACT_APP_ALERT_BANNER_MESSAGE && 
            <Alert variant='primary'>
                {process.env.REACT_APP_ALERT_BANNER_MESSAGE}
            </Alert>
            }
            <a href="/" >
                <img src={logo} alt="Boomerang Logo" className="boomLogo tb2rem" />
            </a>
            <Card className="card_custom">
                <Card.Body>
                    <Card.Title>Portal Login</Card.Title>
                    <Login setUser={props.setUser} />
                </Card.Body>
            </Card>
            <Card className="card_custom">
                <Card.Body>
                    New user? <a href="/Signup">Create an account</a>
                </Card.Body>
            </Card>
        </div>
    )
}
export default LoginScreen
