import React from 'react'
import { withRouter } from 'react-router-dom'
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { FaTaxi, FaRegCalendarCheck, FaRegCalendarAlt, FaRegCalendarPlus, FaHeadset, FaUserEdit } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import './sidebarNav.css'

// https://reactjsexample.com/react-side-nav-component/
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

const SidebarNav = props => {
    return (
        <React.Fragment>
            <SideNav
                className={`sideNav_c ${!props.expanded ? 'collapsed' : ''}`}
                onSelect={(path) => props.history.push( '/home' + path )
                }
            >
                <SideNav.Toggle onClick={() => props.setExpanded(!props.expanded)} expanded={props.expanded} />                
                <SideNav.Nav defaultSelected={`/${props.location.pathname.split('/').slice(2).join('/')}`} >
                    <NavItem title="Today's Rides" eventKey="/rides/today">
                        <NavIcon>
                            <IconContext.Provider value={{ color: 'black', size: '1rem', title: "new rides", style: { margin: '0 .125rem' } }}>
                                <FaTaxi />
                            </IconContext.Provider>
                            <IconContext.Provider value={{ color: 'black', size: '1.25rem', title: "new rides", style: { margin: '0 .125rem' } }}>
                                <FaRegCalendarCheck />
                            </IconContext.Provider>
                        </NavIcon>
                        <NavText>
                            <div style={{color: 'black'}} className="navText_c custom">| Today's Rides</div>
                        </NavText>
                    </NavItem>
                    <NavItem title="Future Rides" eventKey="/rides/future">
                        <NavIcon>
                            <IconContext.Provider value={{ color: 'black', size: '1rem', title: "new rides", style: { margin: '0 .125rem' } }}>
                                <FaTaxi />
                            </IconContext.Provider>
                            <IconContext.Provider value={{ color: 'black', size: '1.25rem', title: "new rides", style: { margin: '0 .125rem' } }}>
                                <FaRegCalendarAlt />
                            </IconContext.Provider>
                        </NavIcon>
                        <NavText>
                            <div  style={{color: 'black'}} className="navText_c custom">| Future Rides</div>
                        </NavText>
                    </NavItem>
                    <NavItem title="New Ride Request" eventKey="/rides/new">
                        <NavIcon>
                            <IconContext.Provider value={{ color: 'black', size: '1rem', title: "future rides", style: { margin: '0 .125rem' } }}>
                                <FaTaxi />
                            </IconContext.Provider>
                            <IconContext.Provider value={{ color: 'black', size: '1.25rem', title: "future rides", style: { margin: '0 .125rem' } }}>
                                <FaRegCalendarPlus />
                            </IconContext.Provider>

                        </NavIcon>
                        <NavText>
                            <div  style={{color: 'black'}} className="navText_c custom">| New Ride Request</div>
                        </NavText>
                    </NavItem>
                    <NavItem title="Edit Profile" eventKey="/profile">
                        <NavIcon>
                            <IconContext.Provider value={{ color: 'black', size: '1.5rem', title: "edit profile", style: { margin: '0 .125rem' } }}>
                                <FaUserEdit />
                            </IconContext.Provider>
                        </NavIcon>
                        <NavText>
                            <div  style={{color: 'black'}} className="navText_c custom">| Edit Profile</div>
                        </NavText>
                    </NavItem>
                    <NavItem title="Contact"  eventKey="/contact">
                        <NavIcon>
                            <IconContext.Provider value={{ color: 'black', size: '1.5rem', title: "contact boomerang transport", style: { margin: '0 .125rem' } }}>
                                <FaHeadset />
                            </IconContext.Provider>
                        </NavIcon>
                        <NavText>
                            <div  style={{color: 'black'}} className="navText_c custom">| Contact</div>
                        </NavText>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
        </React.Fragment>
    );
}
export default withRouter(SidebarNav)