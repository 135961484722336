import React, { Component } from 'react'
import Alert from 'react-bootstrap/Alert'
import { withRouter } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import FormErrors from '../../authForms/Helpers/FormErrors'
import Media from '../Helpers/Media'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Password from '../../authForms/Helpers/Password'
import { changeHelper, maskToggler, submitValidator, fileHelper } from '../../../utils/formHelper'
import stdError from '../../../utils/error'
import axios from 'axios'
import styles from './profile.module.css'
import ImageResizeModal from '../Helpers/ImageResizeModal'
import ReusableModal from '../Helpers/ReusableModal'
import 'react-image-crop/dist/ReactCrop.css';

class Profile extends Component {
    constructor() {
        super()
        this.state = {
            croppedUrl: '',
            showRemoveFile: false,
            profileImageSrc: '',
            updated: false,
            media: null,
            userFirstName: '',
            userLastName: '',
            email: '',
            password: '',
            showPassword: false,
            password2: '',
            showPassword2: false,
            errors: [],
            buttonState: false,
            deleteButtonState: false,
            buttonText: "Save",
            deleteButtonText: "Delete Account",
            passBtnText: "Change Password",
            showPasswords: false,
            showModal: false,
            alertVisible: true,
            showDeleteModal: false

        }
        this.setShowRemoveFile = this.setShowRemoveFile.bind(this)
        this.setCrop = this.setCrop.bind(this)
        this.deleteAccountFinal = this.deleteAccountFinal.bind(this)
        this.togglePasswords = this.togglePasswords.bind(this)
    }
    componentDidMount() {
        if (this.props.user?.email && !this.state.email) {
            const { userFirstName, userLastName, email, profileImageUrl, id, alertProfileUpdate } = this.props.user
            this.setState({ userFirstName, userLastName, email, croppedUrl: profileImageUrl, id, alertVisible: Boolean(alertProfileUpdate) })
        }
    }
    componentDidUpdate(props, state) {
        if (props.user.email !== state.email) {
            const { userFirstName, userLastName, email, profileImageUrl, id, alertProfileUpdate } = this.props.user
            this.setState({ userFirstName, userLastName, email, id, croppedUrl: profileImageUrl, alertVisible: Boolean(alertProfileUpdate) })
        }
    }

    setShowRemoveFile(inputEl, cancel, show) {
        if (inputEl) {
            try {
                inputEl.value = '';
                if (inputEl.value) {
                    inputEl.type = "text";
                    inputEl.type = "file";
                }
                return this.setState({ profileImageSrc: '', croppedUrl: '', media: null })
            } catch (e) { console.log(e) }
        } else if (cancel) {
            return this.setState({ showModal: false, profileImageSrc: '', croppedUrl: '', media: null })
        }
        this.setState({ showRemoveFile: show })
    }

    async update() {
        console.log('update')
        const { userFirstName, userLastName, email, password, media, croppedUrl, id } = this.state;
        const data = new FormData();
        data.append('deleteImage', (media && Boolean(croppedUrl)) || (!media && !Boolean(croppedUrl)))
        data.append('media', media)
        data.append('userFirstName', userFirstName)
        data.append('userLastName', userLastName)
        data.append('email', email)
        data.append('id', id)
        data.append('password', password)
        const result = await axios.put('/api/s3/profile', data, { headers: { 'content-type': undefined } }).catch(e => stdError(e, this, "Save"))
        if (result && result.data) {
            if (result.data.user)
                result.data.user.alertProfileUpdate = true
            this.props.setUser({ user: result.data.user, superUser: result.data.superUser, loggedIn: result.data.user?.id ? true : false })
            window.setTimeout(() => {
                if (result.data.user)
                    delete result.data.user.alertProfileUpdate
                this.props.setUser({ user: result.data.user })
            }, 3000)
            // this.props.history.push("/home/rides/today");
        }
    }
    deleteAccountModal() {
        console.log('Delete account modal clicked')
        this.setState({ showDeleteModal: true })
    }
    togglePasswords() {
        console.log('toggle passwords clicked')
        if (this.state.showPasswords) {
            this.setState({
                showPasswords: false,
                passBtnText: "Change Password",
                password: '',
                password2: ''
            })
        } else {
            this.setState({
                showPasswords: true,
                passBtnText: "Hide Password"
            })
        }
    }
    async deleteAccountFinal() {
        console.log('Delete account final clicked')
        this.setState({ showDeleteModal: false })
        const success = await axios.delete(`/api/deleteuser?id=${this.props.user.id}`)
        if (success.data.delete) {
            this.props.history.push("/")
        }
    }
    setCrop(media, croppedUrl, showModal) {
        this.setState({ media, croppedUrl, showModal })
    }
    render() {
        return (
            <div className="form-wrapper">
                <Form className="form-body" autoComplete="new-password" >
                    <div className="form-heading">Profile</div>
                    <Media user={this.props.user} img={this.state.croppedUrl} setShowRemoveFile={this.setShowRemoveFile} show={this.state.showRemoveFile} onChange={e => { fileHelper(e, this) }} />
                    <Form.Group className="mb-point-25-rem" controlId="formBasicEmail">
                        <Form.Label className="mb-point-25-rem">First Name</Form.Label>
                        <FormControl type="text" name="userFirstName" value={this.state.userFirstName} onChange={e => changeHelper(e, this)} placeholder="First Name" className="mr-sm-2" />
                    </Form.Group >
                    
                    <Form.Group className="mb-point-25-rem" controlId="formBasicEmail">
                        <Form.Label className="mb-point-25-rem">Last Name</Form.Label>
                        <FormControl type="text" name="userLastName" value={this.state.userLastName} onChange={e => changeHelper(e, this)} placeholder="Last Name" className="mr-sm-2" />
                    </Form.Group>
                    
                    <Form.Group className="mb-point-25-rem" controlId="formBasicEmail">
                        <Form.Label className="mb-point-25-rem">Email</Form.Label>
                        <FormControl type="text" name="email" readOnly value={this.state.email} onChange={e => changeHelper(e, this)} placeholder="Email" className="mr-sm-2" />
                    </Form.Group>

                    {this.state.showPasswords && (
                        <React.Fragment>
                            <Form.Group className="mb-point-25-rem" controlId="formBasicEmail" >
                                <Form.Label className="mb-point-25-rem">New Password</Form.Label>
                                <Password name="password" showPassword={this.state.showPassword} value={this.state.password} handleChange={e => changeHelper(e, this)} handleView={() => maskToggler('showPassword', this)} viewKey="showPassword" placeholder="New Password (optional)" />
                            </Form.Group>

                            <Form.Group className="mb-point-25-rem" controlId="formBasicEmail">
                                <Form.Label className="mb-point-25-rem">Confirm Password</Form.Label>
                                <Password name="password2" showPassword={this.state.showPassword2} value={this.state.password2} handleChange={e => changeHelper(e, this)} handleView={() => maskToggler('showPassword2', this)} viewKey="showPassword2" placeholder={(this.state.password && 'Confirm Password (required)') || 'Confirm Password (optional)'} />
                            </Form.Group>
                        </React.Fragment>
                    )}

                    <Button variant="outline-success" disabled={this.state.buttonState} onClick={e => submitValidator(e, this, 'update', true)} className="tb_sm_rem" >{this.state.buttonText}</Button>
                    <Button variant="outline-warning" onClick={e => this.togglePasswords()} className={styles.passBtn} >{this.state.passBtnText}</Button>
                    <Button variant="outline-danger" disabled={this.state.deleteButtonState} onClick={e => this.deleteAccountModal()} className={styles.deleteBtn} >{this.state.deleteButtonText}</Button>
                    <Alert variant="success" show={this.state.alertVisible}  >
                        Profile Updated!
                    </Alert>
                    <FormErrors errors={this.state.errors} />
                </Form>
                <ImageResizeModal
                    show={this.state.showModal}
                    onHide={() => this.setState({ showModal: !this.state.showModal })}
                    cancel={this.setShowRemoveFile}
                    setCrop={this.setCrop}
                    src={this.state.profileImageSrc}
                />
                <ReusableModal
                    closeButton="true"
                    show={this.state.showDeleteModal}
                    onHide={() => this.setState({ showDeleteModal: false })}
                    handleSave={this.deleteAccountFinal}
                    header="true"
                    title="Warning- This is Irreversible"
                    body="Deleting your account will also erase all of your data. 
                Are you sure you want to proceed?"
                    saveButtonVariant="danger"
                    closeButtonVariant="secondary"
                    saveText="Delete Account"
                    closeText="Cancel"

                />
            </div>
        )
    }
}
export default withRouter(Profile);



